.rf-free-tools-body{
  text-align: center !important;

}
.title-area p{
  line-height: 1.5rem;
}
.container{
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
}
.center {
  text-align: center;
  list-style-position: inside;
}
.fullHeightButton{
  height: 100%;
}
.bottom-links{
  bottom: 0;
  width: 100%;
  padding:2rem 0;
}
.bottom-links a{
  padding-top: 1.5rem;
  display: inline-block;
  margin: 0 auto;
  font-size: .8rem;
  text-decoration: none;
  color:rgba(0,0,0,.5);
}
.limits-modal,.comment-count-modal{
  max-width: 600px;
  margin: 0 auto;
  background-color: rgba(255,255,255,1);
  margin-top:1rem;
  border-radius: 4px;
  padding:1rem;
  text-align: justify;
}
.modal-closer{
  float: right;
}
.tool-description{
  padding-top: 1.5rem;
}
.tool-description button{
  width: 100%;
  max-width: 500px;
  margin: 1rem 0;
  text-align: center;
}
.yt-comment-winners-container{
  padding: 1.5rem;
  text-align: center;
}
.winning-comment{
  margin: 1rem 0;
  padding: 1rem;
  border: solid 1px rgba(0,0,0,.05);
  border-radius: 4px;
  text-align: left;
}
.yt-comment-winners-container h1{
  font-weight:200;
  font-size: 3rem;
}
.yt-comment-winners-container p{
  padding-bottom: 2rem;
}
.comment-name a{
  text-decoration: none;
  font-weight: bolder;
  font-size: 1.2rem;
  color:#000;
}
.comment-image img{
  width:80px;
  height: 80px;
  border-radius: 50%;
}
.comment{
  padding-top:1rem;
}
.share-button-container{
  padding-top:2rem;
}
.share-button-container button{
  margin:.2rem;
}
.w-100{
  width: 100%;
}
.mt-2{
  margin-top: 1.5rem;
}
.container button{
  padding: 1rem;
}
.left-align{
  text-align: left;
}
.right-align{
  text-align: right;
}
@media screen and (max-width: 600px){
  .container{
    max-width: 100%;
    padding:.5rem;
  }
  .title-area{
    line-height: 1.5;
  }

  .bottom-links{
    max-width: 100%;
  }
  .limits-modal,.comment-count-modal{
    max-width: 100%;
  }
  .tool-description button{
    max-width: 100%;
  }
  .w-100{
    width: 100%;
  }
  .mt-2{
    margin-top: 1.5rem;
  }
  .bottom-links{
    position: relative!important;
    bottom: 0;
    width: 100%;
    text-align: center;
    max-width: 900px;
  }
  .comment-image img{
    width:50px;
    height: 50px;
    border-radius: 50%;
  }
  .winning-comment{
    text-align: left;
  }
  .bottom-links .left-align{
    text-align: center!important;
  }
  .bottom-links .right-align{
    text-align: center!important;
  }
}
@media (prefers-color-scheme: dark) {

  .bottom-links a{
    color:rgba(255,255,255,.95)!important;
  }
  .bottom-links a:hover{
    color:rgba(255,255,255,1)!important;
  }
  .limits-modal,.comment-count-modal{
    max-width: 600px;
    margin: 0 auto;
    background-color: rgba(0,0,0,1);
    color:#eee;
    margin-top:1rem;
    border-radius: 4px;
    padding:1rem;
    text-align: justify;
  }
  .comment-name a{
    text-decoration: none;
    font-weight: bolder;
    font-size: 1.2rem;
    color:#fff;
  }
  .winning-comment{
    margin: 1rem 0;
    padding: 1rem;
    border: solid 1px rgba(255,255,255,.05);
    border-radius: 4px;
    text-align: left;
  }
}

.how-comments-are-counted {
  font-weight: bolder !important;
  min-width: auto !important;
  padding: 0 .2rem !important;
}
