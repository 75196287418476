body {
    margin: 0;
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.display-none {
    display: none;
}

.loading-modal {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
}
.loading-modal h3{
    font-size: 2rem;
    font-weight: 200;
    padding:2rem;

}
.loading-modal img {
    width: 100%;
    height: auto;
    max-width: 400px;
}
.text-center {
    text-align: center;
}
.ch-message .MuiAlert-message{
    width: 100%;
}
